var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [!_vm.$attrs.hideTopDivider ? _c('v-divider', {
    staticClass: "grey lighten-2",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "no-gutters": "",
      "align": "stretch"
    }
  }, _vm._l(_vm.items, function (item, index) {
    var _item$cols;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": (_item$cols = item.cols) !== null && _item$cols !== void 0 ? _item$cols : _vm.defaultCols
      }
    }, [_vm.isArray(item) ? _vm._l(item, function (child) {
      return _c('vertical-table-row', _vm._b({
        key: child.term
      }, 'vertical-table-row', child, false), [child.data ? [_c('div', {
        domProps: {
          "innerHTML": _vm._s(child.data)
        }
      })] : _vm.$slots[child.term] ? _vm._t(child.term) : child.node ? _c('v-node', {
        attrs: {
          "node": child.node
        }
      }) : _vm._e()], 2);
    }) : [_c('vertical-table-row', _vm._b({}, 'vertical-table-row', item, false), [item.data ? [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.data)
      }
    })] : _vm.$slots[item.term] ? _vm._t(item.term) : item.node ? _c('v-node', {
      attrs: {
        "node": item.node
      }
    }) : _vm._e()], 2)]], 2);
  }), 1), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }